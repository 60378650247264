<template>
  <div>
      <DirectionsTable />
  </div>
</template>

<script>
import DirectionsTable from '@/components/Dashboard/Directions/DirectionsTable'
export default {
  components: {
    DirectionsTable
  }
}
</script>