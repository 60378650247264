<template>
  <div>
    <a-modal :visible="visible" :closable="false" on-ok="handleOk" centered>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          CANCELAR
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
          INACTIVAR ENTIDADES
        </a-button>
      </template>
      <div class="text-center">
        <h3 class="text-2xl text-blue-dark"><b>Inactivar entidades</b></h3>
        <p>¿Esta seguro desea inactivas las {{companies}} entidades seleccionadas?</p>
        <br>
        <p>Recuerde que para deshacer esta acción deberá activar las entidades de forma individual</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    companies: Number,
    visible: Boolean
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 3000);
    },
    handleCancel(e) {
      this.$emit('toggle-modal', false);
    },
  },
};
</script>
